<template>
    <div class="iframe_wrapper">
        <div class="banner_center">
            <div class="banner_safe">
                <div class="banner_word">
                    <p>慧芯课程中心</p>
                    <p>行业前沿专业课程，校企名师专家亲授</p>
                </div>
            </div>
        </div>
        <iframe  frameborder="0"  scrolling="no" class="huixin_iframe" id="courseIframe" height="960" :src="iframeSrc"></iframe>
    </div>
</template>
<script>
export default {
    name: 'courseSquare',
    data() {
        return{
            iframeSrc:'https://huixinic.gaoxiaobang.com/#/huixin/courses'
        }
    },
    created(){
        
    },
    mounted(){
    }
}
</script>
<style scoped>
.banner_center {
  width: auto;
  height: 340px;
  background: url("~@/assets/Coursecenter_img/coour_bj.png") center center
    no-repeat;
  background-size: cover;
}
.banner_safe{
  width: 1200px;
  margin: 0 auto;
  height: 340px;
  overflow: hidden;
}
.banner_word{
  height: 136px;
  width: 590px;
  margin-top: 95px;
  margin-left: 4%;
}
.banner_word p:first-child{
height: 87px;
font-size: 62px;
font-family: PingFangSC-Semibold, PingFang SC,PingFang-SC-Regular;
font-weight: 600;
color: #000000;
line-height: 87px;
}
.banner_word p:last-child{
height: 33px;
font-size: 24px;
font-family: PingFangSC-Medium, PingFang SC,PingFang-SC-Regular;
font-weight: 500;
color: #000000;
line-height: 33px;
margin-top: 16px;
opacity: 0.7;
}
</style>
